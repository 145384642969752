<template>
  <div class="website-tab-overview">
    <website-payment-definition-add-new
      v-model="isAddNewPaymentDefinitionSidebarActive"
      :website="website"
      @refetch-data="fetchWebsitePaymentDefinitions(website.id)"
    ></website-payment-definition-add-new>
    <website-payment-definition-edit
      :is-website-payment-definition-edit-dialog-open.sync="isWebsitePaymentDefinitionEditDialogOpen"
      :website="website"
      :website-payment-definition-data="selectedWebsitePaymentDefinition"
      @refetch-data="fetchWebsitePaymentDefinitions(website.id)"
    ></website-payment-definition-edit>
    <v-card class="mb-7">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center flex-wrap font-weight-bold text-xl">Bin & Caid</div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-bin-and-caid mb-4 me-3"
            @click.stop="isAddNewPaymentDefinitionSidebarActive = !isAddNewPaymentDefinitionSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="tableColumns"
        :items="websitePaymentDefinitionsListTable"
        :item-class="websitePaymentDefinitionItemClass"
        :loading="loading"
      >
        <!-- bin -->
        <template #[`item.bin`]="{ item }">
          <div class="website-payment-definition-item-bin">{{ item.bin }}</div>
        </template>

        <!-- caid -->
        <template #[`item.caid`]="{ item }">
          <div class="website-payment-definition-item-caid">{{ item.caid }}</div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn id="website-payment-definition-actions-button" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="website-payment-definition-item-edit"
                @click="
                  selectedWebsitePaymentDefinition = item
                  isWebsitePaymentDefinitionEditDialogOpen = !isWebsitePaymentDefinitionEditDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="website-payment-definition-item-delete" @click="deleteWebsitePaymentDefinition(item, website)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiKeyboardBackspace,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import WebsitePaymentDefinitionAddNew from '@/views/shared/websites/website-profile/website-profile-settings/website-payment-definitions/WebsitePaymentDefinitionAddNew.vue';
import WebsitePaymentDefinitionEdit from '@/views/shared/websites/website-profile/website-profile-settings/website-payment-definitions/WebsitePaymentDefinitionEdit.vue';
import useWebsitePaymentDefinitionsList from '@/views/shared/websites/website-profile/website-profile-settings/website-payment-definitions/useWebsitePaymentDefinitionsList';

export default {
  components: {
    WebsitePaymentDefinitionAddNew,
    WebsitePaymentDefinitionEdit,
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { websitePaymentDefinitionsListTable, tableColumns, loading, fetchWebsitePaymentDefinitions } =
      useWebsitePaymentDefinitionsList(props.website.id);

    const selectedWebsitePaymentDefinition = null;
    const isWebsitePaymentDefinitionEditDialogOpen = ref(false);
    const isAddNewPaymentDefinitionSidebarActive = ref(false);

    const deleteWebsitePaymentDefinition = (paymentDefinition, website) => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${paymentDefinition.bin} & ${paymentDefinition.caid}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('websitePaymentDefinition/deletePaymentDefinition', {
                paymentDefinitionId: paymentDefinition.id,
              })
              .then(() => fetchWebsitePaymentDefinitions(website.id))
              .catch(() => {
                Vue.notify({
                  type: 'error',
                  title: 'Website Bin & Caid',
                  text: 'Cannot delete website bin & caid',
                });
              });
          }
        },
      });
    };

    const websitePaymentDefinitionItemClass = () => {
      return 'website-payment-definition-item';
    };

    return {
      tableColumns,
      websitePaymentDefinitionsListTable,
      selectedWebsitePaymentDefinition,
      isWebsitePaymentDefinitionEditDialogOpen,
      isAddNewPaymentDefinitionSidebarActive,
      fetchWebsitePaymentDefinitions,
      deleteWebsitePaymentDefinition,
      websitePaymentDefinitionItemClass,
      loading,
      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiChartTimeline,
        mdiKeyboardBackspace,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
