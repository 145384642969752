import store from '@/store';
import { ref } from '@vue/composition-api';
import Vue from 'vue';

export default function useWebsiteNotificationEmailsList(websiteId) {
  const websiteNotificationEmailsListTable = ref([]);

  const tableColumns = [
    { text: 'ID', value: 'id', sortable: true },
    { text: 'Email', value: 'email', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const loading = ref(false);

  // fetch data
  const fetchWebsiteNotificationEmails = websiteId => {
    store
      .dispatch('websiteNotificationEmail/fetchWebsiteNotificationEmailsByWebsiteId', websiteId)
      .then(response => {
        websiteNotificationEmailsListTable.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Website Notification Emails',
          text: `Cannot fetch website notification emails for website with id ${websiteId}`,
        });
      });
  };

  fetchWebsiteNotificationEmails(websiteId);

  return {
    websiteNotificationEmailsListTable,
    tableColumns,
    loading,
    fetchWebsiteNotificationEmails,
  };
}
