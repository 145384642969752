<template>
  <v-dialog
    v-model="isWebsiteStripeChargebacksAutomationDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-website-stripe-chargebacks-automation-dialog-open', false)"
  >
    <v-card class="edit-merchant-website-event-pricing-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Stripe Chargebacks Automation</v-card-title>

      <v-card-text v-if="websiteDataLocal && websiteDataLocal.stripeAutomationSettings"
                   class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation website-stripe-settings-form"
                @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12">
              <v-switch
                dense
                hide-details
                v-model="websiteDataLocal.stripeAutomationSettings.enabled"
                label="Enable Stripe Chargebacks automation"></v-switch>
            </v-col>

            <v-col v-if="websiteDataLocal.stripeAutomationSettings.enabled" cols="12">
              <v-alert color="warning" text class='mb-6'>
                <p class="text-sm text-justify mb-0">
                  Please ask the merchant to set u a new Restricted Key at Stripe
                  <code>Developers</code> menu.
                  The new Restricted Key must have several permissions: <code>Charges: Write</code>;
                  <code>Invoices: Read</code> and <code>Subscriptions: Write</code>.
                </p>
              </v-alert>
              <p>Please enter the Stripe Restricted Key, received from a merchant:</p>
              <v-text-field
                v-model="websiteDataLocal.stripeAutomationSettings.stripeApiKey"
                outlined
                dense
                id="website-stripe-automation-api-restricted-key"
                label="Stripe API Restricted Key"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col v-if="websiteDataLocal.stripeAutomationSettings.enabled" cols="12">
              <v-alert color="warning" text class='mb-6'>
                <p class="text-sm text-justify mb-0">
                  There is a possibility to search for corresponding subscription after automatic
                  refund and cancel it.
                  To enable this feature, please activate the switcher below.
                </p>
              </v-alert>
              <v-switch
                dense
                hide-details
                v-model="websiteDataLocal.stripeAutomationSettings.stopSubscriptionAfterRefund"
                label="Automated subscription cancelling after successful refund"></v-switch>
            </v-col>

            <v-col v-if="websiteDataLocal.stripeAutomationSettings.enabled" cols="12">
              <v-alert color="warning" text class='mb-6'>
                <p class="text-sm text-justify mb-0">
                  There is a possibility to send the Stripe Automation log events to a delegated
                  Slack channel additionally.
                  To enable this feature, please enter the Slack Channel id in the box below. If
                  there is no need to activate, just
                  leave it empty.
                </p>
              </v-alert>
              <v-text-field
                v-model="websiteDataLocal.stripeAutomationSettings.separatedSlackChannelId"
                outlined
                dense
                id="website-stripe-automation-api-restricted-key"
                label="Slack Channel id"
              ></v-text-field>
            </v-col>
            <v-col v-if="websiteDataLocal.stripeAutomationSettings.enabled" cols="12">
              <v-switch
                dense
                hide-details
                v-model="websiteDataLocal.stripeAutomationSettings.hasNote"
                label="Include note to refund metadata"></v-switch>
            </v-col>
          </v-row>
          <v-col cols="12" class="d-flex justify-center mt-3">
            <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit
            </v-btn>

            <v-btn outlined color="secondary"
                   @click.prevent="$emit('update:is-website-stripe-chargebacks-automation-dialog-open', false)">
              Discard
            </v-btn>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { emailValidator, required, passwordValidatorIf } from '@core/utils/validation';
import Vue from 'vue';

export default {
  props: {
    isWebsiteStripeChargebacksAutomationDialogOpen: {
      type: Boolean,
      required: true,
    },
    websiteData: {
      required: false,
    },
  },
  setup(props, { emit }) {
    const websiteDataLocal = ref({});
    websiteDataLocal.value = JSON.parse(JSON.stringify(props.websiteData));

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('website/updateWebsite', {
            id: props.websiteData.id,
            website: websiteDataLocal.value,
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-website-stripe-chargebacks-automation-dialog-open', false);
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    watch(
      () => props.isWebsiteStripeChargebacksAutomationDialogOpen,
      async () => {
        websiteDataLocal.value = JSON.parse(JSON.stringify(props.websiteData));
      },
    );

    return {
      websiteDataLocal,
      form,
      onSubmit,
      valid,
      validate,
      validators: { required, passwordValidatorIf, emailValidator },
    };
  },
};
</script>

<style lang="scss" scoped>
.website-stripe-settings-form {
  ::v-deep .v-input--switch.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
