<template>
  <v-row class="website">
    <!-- user profile -->
    <v-col v-if="websiteData" cols="12">
      <v-card class="pt-5">
        <v-card-title class="justify-center flex-column">
          <span class="website-name-title mb-2">{{ websiteData.name }}</span>
        </v-card-title>

        <v-card-text class='website-details'>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Name:</span>
              <span class="website-name text--secondary">
                {{ websiteData.name }}
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">MCC:</span>
              <span class="website-mcc text--secondary">
                {{ websiteData.mcc }}
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Status:</span>
              <span class="website-status text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveWebsiteStatusVariant(websiteData.status)"
                  :class="`v-chip-light-bg ${resolveWebsiteStatusVariant(
                    websiteData.status,
                  )}--text font-weight-medium text-capitalize`"
                >
                  {{ websiteData.status }}
                </v-chip>
              </span>
            </v-list-item>
          </v-list>
          <v-card-actions class="justify-center mt-5">
            <v-btn color="primary" class="website-edit-button" @click="isWebsiteEditDialogOpen = !isWebsiteEditDialogOpen"> Edit </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text v-if="user && user.reseller" class='reseller-price-config'>
          <h2 class="text-xl font-weight-semibold mb-2">Reseller Price</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Reseller MC Event cost:</span>
              <span class="reseller-ethoca-cost text--secondary">
                {{ websiteData.resellerEthocaCost }}
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Reseller RDR Event cost:</span>
              <span class="reseller-rdr-cost text--secondary">
                {{ websiteData.resellerRdrCost }}
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Reseller CDRN Event cost:</span>
              <span class="reseller-cdrn-cost text--secondary">
                {{ websiteData.resellerCdrnCost }}
              </span>
            </v-list-item>
          </v-list>
          <v-card-actions class="justify-center mt-5">
            <v-btn color="primary" class="reseller-price-edit-button me-3" @click="isWebsiteEventResellerPricingDialogOpen = !isWebsiteEventResellerPricingDialogOpen"> Edit </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-text class='website-stripe-automation'>
          <h2 class="text-xl font-weight-semibold mb-2">Stripe Automation</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Status:</span>
              <span class="website-stripe-automation-status text--secondary">
                {{ websiteData.stripeAutomationSettings && websiteData.stripeAutomationSettings.enabled ? 'Enabled' : 'Disabled' }}
              </span>
            </v-list-item>
            <v-list-item v-if="websiteData.stripeAutomationSettings && websiteData.stripeAutomationSettings.enabled" dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Stripe API Restricted Key</span>
              <span class="website-stripe-automation-api-key text--secondary">
                {{ truncateApiKey(websiteData.stripeAutomationSettings.stripeApiKey) }}
              </span>
            </v-list-item>
          </v-list>
          <v-card-actions class="justify-center mt-5">
            <v-btn color="primary" class="stripe-automation-edit-button me-3" @click="isWebsiteStripeChargebacksAutomationDialogOpen = !isWebsiteStripeChargebacksAutomationDialogOpen"> Edit </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
    <website-edit
      :is-website-edit-dialog-open.sync="isWebsiteEditDialogOpen"
      :website-data="websiteData"
      @refetch-data="updateWebsite()"
    ></website-edit>
    <website-event-reseller-pricing
      :is-website-event-reseller-pricing-dialog-open.sync="isWebsiteEventResellerPricingDialogOpen"
      :website-data="websiteData"
      @refetch-data="updateWebsite()"
    ></website-event-reseller-pricing>
    <website-stripe-chargebacks-automation
      :is-website-stripe-chargebacks-automation-dialog-open.sync="isWebsiteStripeChargebacksAutomationDialogOpen"
      :website-data="websiteData"
      @refetch-data="updateWebsite()"
    ></website-stripe-chargebacks-automation>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckCircleOutline
} from '@mdi/js';
import useWebsiteProfile from '@/views/shared/websites/website-profile/useWebsiteProfile';
import { onMounted, ref } from '@vue/composition-api';
import WebsiteEdit from '@/views/shared/websites/website-profile/WebsiteEdit.vue';
import WebsiteEventResellerPricing
  from '@/views/shared/websites/website-profile/WebsiteEventResellerPricing.vue';
import store from '@/store';
import router from '@/router';
import { eventBus } from '@/main';
import WebsiteStripeChargebacksAutomation
  from '@/views/shared/websites/website-profile/WebsiteStripeChargebacksAutomation.vue';

export default {
  components: {
    WebsiteStripeChargebacksAutomation,
    WebsiteEventResellerPricing,
    WebsiteEdit,
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveWebsiteStatusVariant, getWebsiteById } = useWebsiteProfile();
    const user = ref(null);
    const isWebsiteEditDialogOpen = ref(false);
    const isWebsiteEventPricingDialogOpen = ref(false);
    const isWebsiteEventResellerPricingDialogOpen = ref(false);
    const isWebsiteStripeChargebacksAutomationDialogOpen = ref(false);
    const websiteData = ref(props.website);

    const truncateApiKey = (apiKey) => {
      return apiKey.substring(0, 8) + '***';
    };

    const updateWebsite = async () => {
      websiteData.value = await getWebsiteById(props.website.id);
    };

    onMounted(async () => {
      user.value = (await store.dispatch('user/fetchById', router.currentRoute.params.id)).data;
      eventBus.$on('website.refetch', () => {
        updateWebsite();
      });
    });

    return {
      updateWebsite,
      truncateApiKey,
      user,
      websiteData,
      resolveWebsiteStatusVariant,
      isWebsiteEditDialogOpen,
      isWebsiteEventPricingDialogOpen,
      isWebsiteEventResellerPricingDialogOpen,
      isWebsiteStripeChargebacksAutomationDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckCircleOutline,
      },
    };
  },
};
</script>
