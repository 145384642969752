<template>
  <v-navigation-drawer
    :value="isAddNewWebsitePaymentDefinitionSidebarActive"
    temporary
    touchless
    id="add-new-bin-and-caid-bar"
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-website-payment-definition-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Website Bin & Caid</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-website-payment-definition-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="websitePaymentDefinitionData.bin"
            outlined
            dense
            type="number"
            id="website-payment-definition_bin"
            :rules="[validators.required, validators.binValidator]"
            label="Website Bin"
            placeholder="Website Bin"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="websitePaymentDefinitionData.caid"
            outlined
            dense
            id="website-payment-definition_caid"
            :rules="[validators.required]"
            label="Website Caid"
            placeholder="Website Caid"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetWebsitePaymentDefinitionData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js';
import store from '@/store';
import { ref } from '@vue/composition-api';
import { required, binValidator, cleanSpace } from '@core/utils/validation';
import Vue from 'vue';
import { eventBus } from '@/main';

export default {
  model: {
    prop: 'isAddNewWebsitePaymentDefinitionSidebarActive',
    event: 'update:is-add-new-website-payment-definition-sidebar-active',
  },
  props: {
    isAddNewWebsitePaymentDefinitionSidebarActive: {
      type: Boolean,
      required: true,
    },
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankWebsitePaymentDefinitionData = {
      bin: '',
      caid: '',
    };

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const resetForm = () => {
      form.value.reset();
    };

    const websitePaymentDefinitionData = ref(JSON.parse(JSON.stringify(blankWebsitePaymentDefinitionData)));
    const resetWebsitePaymentDefinitionData = () => {
      websitePaymentDefinitionData.value = JSON.parse(JSON.stringify(blankWebsitePaymentDefinitionData));
      resetForm();
      emit('update:is-add-new-website-payment-definition-sidebar-active', false);
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('websitePaymentDefinition/storePaymentDefinition', {
            websiteId: props.website.id,
            paymentDefinition: cleanSpace(websitePaymentDefinitionData.value),
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-add-new-website-payment-definition-sidebar-active', false);
            eventBus.$emit('website.refetch');
            resetWebsitePaymentDefinitionData();
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website Bin & Caid',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    return {
      resetWebsitePaymentDefinitionData,
      form,
      onSubmit,
      websitePaymentDefinitionData,
      valid,
      validate,
      validators: { required, binValidator },
      icons: {
        mdiClose,
      },
    };
  },
};
</script>
