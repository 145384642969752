<template>
  <v-dialog
    v-model="isWebsiteBillingDescriptorEditDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-website-billing-descriptor-edit-dialog-open', false)"
  >
    <v-card class="edit-website-billing-descriptor-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Website Billing Descriptor </v-card-title>

      <v-card-text v-if="websiteBillingDescriptorDataLocal" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="websiteBillingDescriptorDataLocal.name"
                outlined
                dense
                id="website-descriptor_name"
                :rules="[validators.required]"
                label="Website Billing Descriptor"
                placeholder="Website Billing Descriptor"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-website-billing-descriptor-edit-dialog-open', false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { required } from '@core/utils/validation';
import Vue from 'vue';
import { eventBus } from '@/main';

export default {
  props: {
    isWebsiteBillingDescriptorEditDialogOpen: {
      type: Boolean,
      required: true,
    },
    websiteBillingDescriptorData: {
      required: false,
    },
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const websiteBillingDescriptorDataLocal = ref({});

    websiteBillingDescriptorDataLocal.value = JSON.parse(JSON.stringify(props.websiteBillingDescriptorData));

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {

        const fields = ['name'];
        fields.forEach(field => {
          websiteBillingDescriptorDataLocal.value[field] = websiteBillingDescriptorDataLocal.value[field].trim();
        });

        store
          .dispatch('websiteBillingDescriptor/updateWebsiteBillingDescriptor', {
            websiteId: props.website.id,
            websiteBillingDescriptorId: props.websiteBillingDescriptorData.id,
            websiteBillingDescriptor: websiteBillingDescriptorDataLocal.value,
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-website-billing-descriptor-edit-dialog-open', false);
            eventBus.$emit('website.refetch');
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website Billing Descriptor',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    watch(
      () => props.isWebsiteBillingDescriptorEditDialogOpen,
      async () => {
        websiteBillingDescriptorDataLocal.value = JSON.parse(JSON.stringify(props.websiteBillingDescriptorData));
      },
    );

    return {
      form,
      websiteBillingDescriptorDataLocal,
      onSubmit,
      valid,
      validate,
      validators: { required },
    };
  },
};
</script>
