<template>
  <div class="website-tab-overview">
    <website-billing-descriptor-add-new
      v-model="isAddNewBillingDescriptorSidebarActive"
      :website="website"
      @refetch-data="fetchWebsiteBillingDescriptors(website.id)"
    ></website-billing-descriptor-add-new>
    <website-billing-descriptor-edit
      :is-website-billing-descriptor-edit-dialog-open.sync="isWebsiteBillingDescriptorEditDialogOpen"
      :website="website"
      :website-billing-descriptor-data="selectedWebsiteBillingDescriptor"
      @refetch-data="fetchWebsiteBillingDescriptors(website.id)"
    ></website-billing-descriptor-edit>
    <v-card class="mb-7">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center flex-wrap font-weight-bold text-xl">Billing Descriptor</div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-billing-descriptor mb-4 me-3"
            @click.stop="isAddNewBillingDescriptorSidebarActive = !isAddNewBillingDescriptorSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="tableColumns"
        :items="websiteBillingDescriptorsListTable"
        :item-class="websiteBillingDescriptorItemClass"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn id="merchant-website-billing-descriptor-actions-button" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="website-billing-descriptor-list-item-edit"
                @click="
                  selectedWebsiteBillingDescriptor = item
                  isWebsiteBillingDescriptorEditDialogOpen = !isWebsiteBillingDescriptorEditDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="website-billing-descriptor-list-item-delete" @click="deleteWebsiteBillingDescriptor(item, website)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiKeyboardBackspace,
} from '@mdi/js';
import useWebsiteBillingDescriptorsList from '@/views/shared/websites/website-profile/website-profile-settings/website-billing-descriptors/useWebsiteBillingDescriptorsList';
import { ref } from '@vue/composition-api';
import WebsiteBillingDescriptorAddNew from '@/views/shared/websites/website-profile/website-profile-settings/website-billing-descriptors/WebsiteBillingDescriptorAddNew.vue';
import WebsiteBillingDescriptorEdit from '@/views/shared/websites/website-profile/website-profile-settings/website-billing-descriptors/WebsiteBillingDescriptorEdit.vue';
import store from '@/store';
import Vue from 'vue';

export default {
  components: {
    WebsiteBillingDescriptorAddNew,
    WebsiteBillingDescriptorEdit,
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { websiteBillingDescriptorsListTable, tableColumns, loading, fetchWebsiteBillingDescriptors } =
      useWebsiteBillingDescriptorsList(props.website.id);

    const selectedWebsiteBillingDescriptor = null;
    const isWebsiteBillingDescriptorEditDialogOpen = ref(false);
    const isAddNewBillingDescriptorSidebarActive = ref(false);

    const deleteWebsiteBillingDescriptor = (billingDescriptor, website) => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${billingDescriptor.name}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('websiteBillingDescriptor/deleteWebsiteBillingDescriptor', {
                websiteId: website.id,
                websiteBillingDescriptorId: billingDescriptor.id,
              })
              .then(() => fetchWebsiteBillingDescriptors(website.id))
              .catch(() => {
                Vue.notify({
                  type: 'error',
                  title: 'Website Billing Descriptors',
                  text: 'Cannot delete website billing descriptor',
                });
              });
          }
        },
      });
    };

    const websiteBillingDescriptorItemClass = () => {
      return 'website-billing-descriptor-item';
    };

    return {
      tableColumns,
      websiteBillingDescriptorsListTable,
      selectedWebsiteBillingDescriptor,
      isWebsiteBillingDescriptorEditDialogOpen,
      isAddNewBillingDescriptorSidebarActive,
      fetchWebsiteBillingDescriptors,
      deleteWebsiteBillingDescriptor,
      loading,
      websiteBillingDescriptorItemClass,
      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiChartTimeline,
        mdiKeyboardBackspace,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
