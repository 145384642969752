<template>
  <v-dialog
    v-model="isWebsiteEventResellerPricingDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-website-event-reseller-pricing-dialog-open', false)"
  >
    <v-card class="edit-merchant-website-event-reseller-pricing-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Events Reseller Pricing </v-card-title>

      <v-card-text v-if="websiteDataLocal" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="websiteDataLocal.resellerEthocaCost"
                  outlined
                  dense
                  id="website-reseller-ethoca_cost"
                  label="Reseller MC events cost"
                  :rules="[validators.required]"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="websiteDataLocal.resellerRdrCost"
                  outlined
                  dense
                  :rules="[validators.required]"
                  id="website-reseller-rdr_cost"
                  label="Reseller RDR events cost"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="websiteDataLocal.resellerCdrnCost"
                  outlined
                  dense
                  id="website-reseller-cdrn_cost"
                  label="Reseller CDRN events cost"
                  :rules="[validators.required]"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-website-event-reseller-pricing-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { emailValidator, required, passwordValidatorIf } from '@core/utils/validation';
import Vue from 'vue';

export default {
  props: {
    isWebsiteEventResellerPricingDialogOpen: {
      type: Boolean,
      required: true,
    },
    websiteData: {
      required: false,
    },
  },
  setup(props, { emit }) {
    const websiteDataLocal = ref({});
    websiteDataLocal.value = JSON.parse(JSON.stringify(props.websiteData));

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('website/updateWebsite', { id: props.websiteData.id, website: websiteDataLocal.value })
          .then(() => {
            emit('refetch-data');
            emit('update:is-website-event-reseller-pricing-dialog-open', false);
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    watch(
      () => props.isWebsiteEventResellerPricingDialogOpen,
      async () => {
        websiteDataLocal.value = JSON.parse(JSON.stringify(props.websiteData));
      },
    );

    return {
      websiteDataLocal,
      form,
      onSubmit,
      valid,
      validate,
      validators: { required, passwordValidatorIf, emailValidator },
    };
  },
};
</script>
