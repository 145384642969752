<template>
  <div v-if="website" id="website-view">
    <v-card class="mb-5">
      <v-card-title class="align-start cursor-pointer">
        <div @click="$router.push({name: 'merchant-view', params: { id: router.currentRoute.params.id }})">
          <v-icon>{{ mdiArrowLeftBoldCircleOutline}}</v-icon>
          <div class="d-inline-flex back-to-merchant">Back to merchant</div>
        </div>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <website-profile-detail :website="website"></website-profile-detail>
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <website-billing-descriptor :website="website"></website-billing-descriptor>
        <website-payment-definitions :website="website"></website-payment-definitions>
        <website-notification-email :website="website"></website-notification-email>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import WebsiteProfileDetail from '@/views/shared/websites/website-profile/WebsiteDetail.vue';
import store from '@/store';
import router from '@/router';
import Vue from 'vue';
import { ref } from '@vue/composition-api';
import { mdiArrowLeftBoldCircleOutline, mdiArrowRightBoldCircleOutline } from '@mdi/js';
import WebsiteNotificationEmail
  from '@/views/shared/websites/website-profile/website-profile-settings/website-notification-emails/WebsiteNotificationEmail.vue';
import WebsiteBillingDescriptor
  from '@/views/shared/websites/website-profile/website-profile-settings/website-billing-descriptors/WebsiteBillingDescriptor.vue';
import WebsitePaymentDefinitions
  from '@/views/shared/websites/website-profile/website-profile-settings/website-payment-definitions/WebsitePaymentDefinitions.vue';

export default {
  components: {
    WebsitePaymentDefinitions,
    WebsiteBillingDescriptor,
    WebsiteNotificationEmail,
    WebsiteProfileDetail
  },
  setup() {
    const website = ref(null);

    const getDefaultWebsiteStripeSettings = () => {
      return {
        enabled: false,
        stripeApiKey: null,
        stopSubscriptionAfterRefund: false,
        hasNote: false,
      };
    };

    store
      .dispatch('website/fetchWebsiteById', router.currentRoute.params.websiteId)
      .then((data) => {
        website.value = data;
        if (!data.stripeAutomationSettings) {
          website.value.stripeAutomationSettings = getDefaultWebsiteStripeSettings();
        }
      })
      .catch(e => {
        Vue.notify({
          type: 'error',
          title: 'Website',
          text: e.response.data.message,
          duration: 5000,
        });
      });

    return {
      website,
      router,
      mdiArrowLeftBoldCircleOutline,
      mdiArrowRightBoldCircleOutline,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.back-button-name {
  vertical-align: middle;
}
</style>
