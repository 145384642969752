import store from '@/store';
import Vue from 'vue';

export default function useWebsiteProfile() {
  const resolveWebsiteStatusVariant = status => {
    if (status === 'active') {
      return 'success';
    }
    return 'error';
  };

  const getWebsiteById = id => {
    return store
      .dispatch('website/fetchWebsiteById', id)
      .then((website) => {
        return website;
      })
      .catch(e => {
        Vue.notify({
          type: 'error',
          title: 'Website',
          text: e.response.data.message,
          duration: 5000,
        });
      });
  };

  return {
    getWebsiteById,
    resolveWebsiteStatusVariant
  };
}
