var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"website-tab-overview"},[_c('website-payment-definition-add-new',{attrs:{"website":_vm.website},on:{"refetch-data":function($event){return _vm.fetchWebsitePaymentDefinitions(_vm.website.id)}},model:{value:(_vm.isAddNewPaymentDefinitionSidebarActive),callback:function ($$v) {_vm.isAddNewPaymentDefinitionSidebarActive=$$v},expression:"isAddNewPaymentDefinitionSidebarActive"}}),_c('website-payment-definition-edit',{attrs:{"is-website-payment-definition-edit-dialog-open":_vm.isWebsitePaymentDefinitionEditDialogOpen,"website":_vm.website,"website-payment-definition-data":_vm.selectedWebsitePaymentDefinition},on:{"update:isWebsitePaymentDefinitionEditDialogOpen":function($event){_vm.isWebsitePaymentDefinitionEditDialogOpen=$event},"update:is-website-payment-definition-edit-dialog-open":function($event){_vm.isWebsitePaymentDefinitionEditDialogOpen=$event},"refetch-data":function($event){return _vm.fetchWebsitePaymentDefinitions(_vm.website.id)}}}),_c('v-card',{staticClass:"mb-7"},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center flex-wrap font-weight-bold text-xl"},[_vm._v("Bin & Caid")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"add-new-bin-and-caid mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewPaymentDefinitionSidebarActive = !_vm.isAddNewPaymentDefinitionSidebarActive}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.websitePaymentDefinitionsListTable,"item-class":_vm.websitePaymentDefinitionItemClass,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.bin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"website-payment-definition-item-bin"},[_vm._v(_vm._s(item.bin))])]}},{key:"item.caid",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"website-payment-definition-item-caid"},[_vm._v(_vm._s(item.caid))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"website-payment-definition-actions-button","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"website-payment-definition-item-edit",on:{"click":function($event){_vm.selectedWebsitePaymentDefinition = item
                _vm.isWebsitePaymentDefinitionEditDialogOpen = !_vm.isWebsitePaymentDefinitionEditDialogOpen}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{staticClass:"website-payment-definition-item-delete",on:{"click":function($event){return _vm.deleteWebsitePaymentDefinition(item, _vm.website)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }