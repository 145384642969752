import store from '@/store';
import { ref } from '@vue/composition-api';
import Vue from 'vue';

export default function useWebsitePaymentDefinitionsList(websiteId) {
  const websitePaymentDefinitionsListTable = ref([]);

  const tableColumns = [
    { text: 'ID', value: 'id', sortable: true },
    { text: 'Bin', value: 'bin', sortable: false },
    { text: 'Caid', value: 'caid', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const loading = ref(false);

  // fetch data
  const fetchWebsitePaymentDefinitions = websiteId => {
    store
      .dispatch('websitePaymentDefinition/fetchPaymentDefinitionsByWebsiteId', websiteId)
      .then(response => {
        websitePaymentDefinitionsListTable.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Website Bin & Caid',
          text: `Cannot fetch website Bin & Caid for website with id ${websiteId}`,
        });
      });
  };

  fetchWebsitePaymentDefinitions(websiteId);

  return {
    websitePaymentDefinitionsListTable,
    tableColumns,
    loading,
    fetchWebsitePaymentDefinitions,
  };
}
