<template>
  <v-dialog
    v-model="isWebsitePaymentDefinitionEditDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-website-payment-definition-edit-dialog-open', false)"
  >
    <v-card class="edit-merchant-website-payment-definition-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Website Bin & Caid </v-card-title>

      <v-card-text v-if="websitePaymentDefinitionDataLocal" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="websitePaymentDefinitionDataLocal.bin"
                outlined
                dense
                type="number"
                id="website-payment-definition_bin"
                :rules="[validators.required, validators.binValidator]"
                label="Website Bin"
                placeholder="Website Bin"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="websitePaymentDefinitionDataLocal.caid"
                outlined
                dense
                id="website-payment-definition_caid"
                :rules="[validators.required]"
                label="Website Caid"
                placeholder="Website Caid"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>
              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-website-payment-definition-edit-dialog-open', false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { binValidator, cleanSpace, required } from '@core/utils/validation';
import Vue from 'vue';
import { eventBus } from '@/main';

export default {
  props: {
    isWebsitePaymentDefinitionEditDialogOpen: {
      type: Boolean,
      required: true,
    },
    websitePaymentDefinitionData: {
      required: false,
    },
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const websitePaymentDefinitionDataLocal = ref({});

    websitePaymentDefinitionDataLocal.value = JSON.parse(JSON.stringify(props.websitePaymentDefinitionData));

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('websitePaymentDefinition/updatePaymentDefinition', {
            paymentDefinitionId: props.websitePaymentDefinitionData.id,
            paymentDefinition: cleanSpace(websitePaymentDefinitionDataLocal.value),
          })
          .then(() => {
            emit('refetch-data');
            emit('update:is-website-payment-definition-edit-dialog-open', false);
            eventBus.$emit('website.refetch');
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website Bin & Caid',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    watch(
      () => props.isWebsitePaymentDefinitionEditDialogOpen,
      async () => {
        websitePaymentDefinitionDataLocal.value = JSON.parse(JSON.stringify(props.websitePaymentDefinitionData));
      },
    );

    return {
      form,
      websitePaymentDefinitionDataLocal,
      onSubmit,
      valid,
      validate,
      validators: { required, binValidator },
    };
  },
};
</script>
