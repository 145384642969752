<template>
  <div class="website-tab-overview">
    <website-notification-email-add-new
      v-model="isAddNewNotificationEmailSidebarActive"
      :website="website"
      @refetch-data="fetchWebsiteNotificationEmails(website.id)"
    ></website-notification-email-add-new>
    <website-notification-email-edit
      :is-website-notification-email-edit-dialog-open.sync="isWebsiteNotificationEmailEditDialogOpen"
      :website="website"
      :website-notification-email-data="selectedWebsiteNotificationEmail"
      @refetch-data="fetchWebsiteNotificationEmails(website.id)"
    ></website-notification-email-edit>
    <v-card class="mb-7">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center flex-wrap font-weight-bold text-xl">Notification emails</div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-notification-email mb-4 me-3"
            @click.stop="isAddNewNotificationEmailSidebarActive = !isAddNewNotificationEmailSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="tableColumns"
        :items="websiteNotificationEmailsListTable"
        :item-class="websiteNotificationEmailItemClass"
        :loading="loading"
      >
        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn id="notification-email-actions-button" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="website-billing-descriptor-list-item-edit"
                @click="
                  selectedWebsiteNotificationEmail = item
                  isWebsiteNotificationEmailEditDialogOpen = !isWebsiteNotificationEmailEditDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="website-billing-descriptor-list-item-delete" @click="deleteWebsiteNotificationEmail(item, website)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiKeyboardBackspace,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import WebsiteNotificationEmailAddNew
  from '@/views/shared/websites/website-profile/website-profile-settings/website-notification-emails/WebsiteNotificationEmailAddNew.vue';
import WebsiteNotificationEmailEdit
  from '@/views/shared/websites/website-profile/website-profile-settings/website-notification-emails/WebsiteNotificationEmailEdit.vue';
import useWebsiteNotificationEmailsList
  from '@/views/shared/websites/website-profile/website-profile-settings/website-notification-emails/useWebsiteNotificationEmailsList';

export default {
  components: {
    WebsiteNotificationEmailAddNew,
    WebsiteNotificationEmailEdit,
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { websiteNotificationEmailsListTable, tableColumns, loading, fetchWebsiteNotificationEmails } =
      useWebsiteNotificationEmailsList(props.website.id);

    const selectedWebsiteNotificationEmail = null;
    const isWebsiteNotificationEmailEditDialogOpen = ref(false);
    const isAddNewNotificationEmailSidebarActive = ref(false);

    const deleteWebsiteNotificationEmail = (notificationEmail, website) => {
      Vue.$confirm({
        message: `Are you sure you want to delete ${notificationEmail.email}?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            store
              .dispatch('websiteNotificationEmail/deleteWebsiteNotificationEmail', {
                websiteId: website.id,
                websiteNotificationEmailId: notificationEmail.id,
              })
              .then(() => fetchWebsiteNotificationEmails(website.id))
              .catch(() => {
                Vue.notify({
                  type: 'error',
                  title: 'Website Notification Emails',
                  text: 'Cannot delete website notification emails',
                });
              });
          }
        },
      });
    };

    const websiteNotificationEmailItemClass = () => {
      return 'website-notification-email-item';
    };

    return {
      tableColumns,
      websiteNotificationEmailsListTable,
      selectedWebsiteNotificationEmail,
      isWebsiteNotificationEmailEditDialogOpen,
      isAddNewNotificationEmailSidebarActive,
      fetchWebsiteNotificationEmails,
      deleteWebsiteNotificationEmail,
      loading,
      websiteNotificationEmailItemClass,
      icons: {
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiChartTimeline,
        mdiKeyboardBackspace,
      },
    };
  },
};
</script>
